<template>
  <app-card-code
    :code="codeSnippets.formLayoutTabs"
    title=""
  >
    <h3 class="ml-5">
      Póliza
      <v-icon
        title="Refrescar vista"
        @click="resetData"
      >
        {{ icons.mdiFindReplace }}
      </v-icon>
    </h3>
    <v-row class="mb-10 mt-5 ml-3 mr-3">
      <!-- columna rut -->
      <v-col
        md="5"
        cols="10"
      >
        <v-text-field
          name="inputRut"
          ref="inputRut"
          v-model="rut"
          v-mask="rut !== '' ? maskRut : null"
          dense
          hide-details
          label="Rut Asegurado"
          :rules="[numberRut.required]"
          :rut="true"
          @input="rutEsValido"
        ></v-text-field>
      </v-col>

      <!-- columba boton buscar -->
      <v-col
        v-if="desactivarbuscador"
        md="1"
        cols="1"
      >
        <v-btn
          name="BotonBuscarRut"
          color="primary"
          elevation="2"
          fab
          small
          :loading="loading"
          :disabled="!rutEsValido()"

          @click="bucarRut(rut)"
        >
          <v-icon>{{ icons.mdiMagnify }}</v-icon>
        </v-btn>
      </v-col>
      <!-- BOTON SE ACTIVARA UNA VEZ QUE SEA VALIDO EL RUT -->
      <v-col
        md="1"
        cols="1"
      >
        <v-btn
          v-if="!desactivarbuscador"
          color="primary"
          elevation="2"
          fab
          small
          :loading="loading"
          :disabled="!rutEsValido"

          @click="refrechData"
        >
          <v-icon>{{ icons.mdiFindReplace }}</v-icon>
        </v-btn>
      </v-col>

      <v-col
        md="4"
        cols="4"
      >
        <v-btn
          class="d-flex justify-end"
          color="primary"
          x-small
          text
          :disabled="!activorut"
          @click="verCliente"
        >
          Ver cliente
          <v-icon class="ml-2">
            {{ icons.mdiAccountDetails }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- inicio de stepper barra principal -->
    <v-row
      v-if="stipeActive == true"
      class="mb-10 mt-5"
      align="center"
      justify="space-around"
    >
      <v-col
        md="12"
        cols="12"
      >
        <v-progress-circular
          style="width:100%;"
          indeterminate
          color="primary"
          :size="50"
          align="center"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row
      v-if="respuestaRut === true"
      class="mb-15 mt-5 ml-3 mr-3 "
    >
      <!-- columna rut -->
      <v-col
        md="12"
        cols="12"
        class="header-inf-basica"
      >
        <h4
          class="header-inf-basica-text"
          style="color:#ffffff !important;"
        >
          Información básica del Asegurado
        </h4>
      </v-col>
      <v-col
        md="4"
        cols="11"
        class="text-inf"
      >
        <span class="font-weight-medium">Rut :</span>
        <span
          v-mask="maskRut"
          class="text--secondary"
        >{{ rutUser }}</span>
      </v-col>
      <v-col
        md="8"
        cols="11"
        class="text-inf"
      >
        <span class="font-weight-medium me-2">Nombres y Apellidos:</span>
        <span class="text--secondary">{{ nombres }} {{ primerApellido }}</span>
      </v-col>
      <v-col
        md="4"
        cols="11"
        class="text-inf"
      >
        <span class="font-weight-medium me-2">Fecha Nacimiento :</span>
        <span class="text--secondary">{{ fechaNacimiento }}</span>
      </v-col>
      <v-col
        md="5"
        cols="11"
        class="text-inf"
      >
        <span class="font-weight-medium me-2">Correo:</span>
        <span class="text--secondary">{{ correo }}</span>
      </v-col>
      <v-col
        md="3"
        cols="11"
        class="text-inf"
      >
        <span class="font-weight-medium me-2">Teléfono:</span>
        <span class="text--secondary">{{ telefono }}</span>
      </v-col>
    </v-row>
    <div class="pl-6 pr-6 pb-6">
      <v-data-table
        v-if="respuestaRut === true"
        :headers="headers"
        :items="productos"
        class="elevation-1 mt-0"
        color="indigo darken-5"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        :loading="loading"
        :search="filter"
      >
        <template
          v-slot:item.fecha_contratacion="{ item }"
          class="textos"
        >
          <span class="text--secondary">{{ item.fecha_contratacion }}</span>
        </template>
        <template
          v-slot:item.accion="{ item }"
          class="textos"
        >
          <!-- firma dosumento -->
          <v-dialog
            v-if="item.estado == 'emision'"
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-3"
                color="primary"
                x-small
                text
                title="Firmar documento"
                v-bind="attrs"
                @click="verdocumentos(item)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiBookEdit }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                dark
                color="primary"
              >
                <!-- primera modal-->
                <div class=" row col-12">
                  <div class="col-md-2 col-2">
                    <v-btn
                      icon
                      dark
                      @click="cerrar_modal"
                    >
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </div>
                  <div class="col-md-7 col-7">
                    <v-toolbar-title class="tollbar-title ">
                      {{ tituloDocumento }}
                    </v-toolbar-title>
                  </div>
                  <div class="col-md-3 col-3 bonton-firma-icon">
                    <v-btn
                      v-if="!firmaActiva"
                      icon
                      dark
                      title="Firmar documento"
                      @click="firmarDocumento()"
                    >
                      <v-icon>{{ icons.mdiPencil }}</v-icon>
                    </v-btn>
                  </div>
                  <div class="col-md-3 col-3 bonton-firma">
                    <v-btn
                      v-if="!firmaActiva"
                      text
                      dark
                      title="Firmar documento"
                      @click="firmarDocumento()"
                    >
                      Firmar documento
                    </v-btn>
                  </div>
                </div>

                <v-spacer></v-spacer>
              </v-toolbar>
              <v-list
                v-if="firmaActiva"
                three-line
                subheader
                class="PDFdoc"
                height="93vh"
              >
                <div class="row">
                  <div class="col-md-12">
                    <object
                      id="iframe_firma"
                      ref="iframe_firma"
                      :data="urlFirma"
                      width="100%"
                      height="800%"
                      frameborder="0"
                      allowfullscreen
                    >
                      <!-- sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals" -->
                      <p> <a
                        href=""
                      >
                        Ocurrió un error invocando al firmador, por favor comuníquese con el administrador (1)
                      </a>
                      </p>
                    </object>
                  </div>
                </div>
              </v-list>
              <v-list
                v-if="!firmaActiva"
                three-line
                subheader
                class="PDFdoc"
                height="93vh"
              >
                <div class="row">
                  <div class="col-md-12">
                    <object
                      width="100%"
                      height="800%"
                      type="application/pdf"
                      :data="documentoPDF"
                      frameborder="0"
                      allowfullscreen
                    >
                      <!-- sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals" -->
                      <p> <a
                        href=""
                      >
                        Ocurrió un error invocando al firmador, por favor comuníquese con el administrador (2)
                      </a>
                      </p>
                    </object>
                  </div>
                </div>
              </v-list>
            </v-card>
          </v-dialog>
          <!-- fin firma documento -->
          <v-btn
            v-if="item.estado !== 'emision'"
            :disabled="item.estado !== 'emision'"
            color="primary"
            x-small
            text
            title="Firmar documento"
          >
            <v-icon>{{ icons.mdiBookEdit }}</v-icon>
          </v-btn>

          <v-btn
            :disabled="item.estado == 'emision'"
            name="detallePoliza"
            color="primary"
            x-small
            text
            title="Detalles póliza"
            @click="verDetalle(item)"
          >
            <v-icon>{{ icons.mdiBookSearch }}</v-icon>
          </v-btn>
          <v-btn
            name="cancelarPoliza"
            :disabled="item.estado == 'anulado'"
            color="primary"
            x-small
            text
            title="Solicitar cancelación póliza"
            @click="cancelar_producto(item)"
          >
            <v-icon>{{ icons.mdiBookRemove }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div v-show="!loading">
        <div
          v-if="respuestaRut === true"
          class="row"
        >
          <div class="col-4">
            <v-row
              class="mt-2"
            >
              <!-- <span class="grey--text">Paginación</span> -->
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    text
                    color="primary"
                    class=""
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ itemsPerPage }}
                    <v-icon>{{ icons.mdiChevronDown }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <span class="mt-2">{{ page }} / {{ pageCount }}</span>

              <v-spacer></v-spacer>
            </v-row>
          </div>
          <div class="col-6">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="6"
              circle
              @click.native="paginar(page)"
            >
            </v-pagination>
          </div>
        </div>
      </div>
      <v-snackbar
        v-model="isFormSubmittedSnackbarVisible2"
        right
        top
        :color="colorSnackbar"
      >
        {{ textSnackbar }}
        <!-- <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="isFormSubmittedSnackbarVisible2 = false"
          >
            <v-icon>{{ icons.mdiCloseThick }}</v-icon>
          </v-btn>
        </template> -->
      </v-snackbar>
    </div>
  </app-card-code>
</template>

<script>
import { ref } from '@vue/composition-api'

import { required } from '@core/utils/validation'
import {
  mdiClose,
  mdiCheck,
  mdiCached,
  mdiMagnify,
  mdiBookSearch,
  mdiBookMultiple,
  mdiBookRemove,
  mdiCloseThick,
  mdiCardAccountDetailsStar,
  mdiAccountDetails,
  mdiFindReplace,
  mdiBookEdit,
} from '@mdi/js'
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import { maskRut, esValidoNumeroRut, cleanMaskRut } from '@/helpers/rut'
import { formLayoutTabs, dialogFullScreen } from './code'
import { polizastService } from '../../../../service/poliza.service'

// import { FirmaService } from '../../../../service/firmador.services'

export default {
  components: {
    AppCardCode,
  },
  setup() {
    // Stepper's active step
    const activeStep = ref(1)
    const requisitosActive = ref(false)

    // Data fromularios
    const rut = ref('')
    const rutUser = ref('')
    const nombres = ref('')
    const fechaNacimiento = ref('')
    const correo = ref('')
    const telefono = ref('')

    const loading = ref(false)
    const activorut = ref(false)

    const stipeActive = ref(false)

    const productos = ref([])

    // Form Submitted Snackbar
    const isFormSubmittedSnackbarVisible = ref(true)
    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')
    const documentoPDF = ref('')

    const totalPage = ref('')
    const filter = ref('')
    const desactivarbuscador = ref(true)

    const dialog = ref(false)
    const correoCliente64 = ref('')
    const idDocumento64 = ref('')
    const name64 = ref('')
    const propuesta64 = ref('')
    const rut64 = ref('')
    const urlPdf64 = ref('')
    const tipoCodigoAcepta64 = ref('')
    const documentoPre64 = ref('')
    const tipoDocumentoCodigo64 = ref('')
    const endoso64 = ref('')
    const urlFirma = ref('')
    const isDialogFirmadorVisible = ref(false)
    const firmaActiva = ref(false)
    const informasionRut = ref(null)
    const informacionPoliza = ref(null)

    return {
      // datos tabla
      headers: [
        { text: 'Producto', value: 'producto' },
        { text: 'Fecha', value: 'fecha_contratacion' },
        { text: 'Estado', value: 'estado' },
        { text: 'Acción', value: 'accion' },
      ],
      productos,
      desactivarbuscador,
      maskRut,
      icons: {
        mdiClose,
        mdiCheck,
        mdiCached,
        mdiMagnify,
        mdiBookSearch,
        mdiBookMultiple,
        mdiBookRemove,
        mdiCloseThick,
        mdiCardAccountDetailsStar,
        mdiAccountDetails,
        mdiFindReplace,
        mdiBookEdit,
      },
      codeSnippets: {
        formLayoutTabs,
        dialogFullScreen,
      },
      activeStep,

      totalPage,
      filter,

      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      itemsPerPageArray: [2, 5, 10, 15, 20, 25],
      perPage: 0,

      // userData,
      rut,
      rutUser,
      nombres,
      fechaNacimiento,
      correo,
      telefono,

      loading,
      activorut,
      informasionRut,
      informacionPoliza,
      documentoPDF,

      // variables firma
      correoCliente64,
      idDocumento64,
      name64,
      propuesta64,
      rut64,
      urlPdf64,
      urlFirma,
      tipoCodigoAcepta64,
      documentoPre64,
      tipoDocumentoCodigo64,
      endoso64,
      isDialogFirmadorVisible,
      firmaActiva,

      // Field Validators
      validators: {
        required,
      },

      requisitosActive,
      stipeActive,

      // Snackbar
      isFormSubmittedSnackbarVisible,
      isFormSubmittedSnackbarVisible2,
      colorSnackbar,
      textSnackbar,
      dialog: false,
      dialogDelete: false,
      numberRut: {
        required: esValidoNumeroRut,
      },
      requerido: {
        required: value => !!value || 'Campo requerido.',
      },
    }
  },
  filters: {
    formatFecha(valor) {
      const dato = valor.split('-')

      return typeof dato === typeof [] ? `${dato[2]}-${dato[1]}-${dato[0]}` : 'Sin información'
    },
  },

  computed: {},

  mounted() {
    window.addEventListener('message', event => {
      if (event.origin === 'https://api.pvdigital.cl' && event.data.mensaje === 'firmaManual') {
        this.firmadorNoValido()
      }
      if (event.origin === 'https://api.pvdigital.cl' && event.data.mensaje === 'firmaDigital') {
        this.firmadorValido()
      }
    })
  },

  created() {
    // limpiando localStorage Informacion Cliente
    localStorage.setItem('informacionCliente', JSON.stringify({}))
    localStorage.setItem('productoPolizaNombre', JSON.stringify({}))

    if (this.$router.currentRoute.params.rut != null) {
      const rut = this.$router.currentRoute.params.rut.toString()
      this.rut = cleanMaskRut(rut)
      console.log(rut)
      this.rutEsValido()
      this.bucarRut(this.$router.currentRoute.params.rut)
    } else {
      this.refrechData()
    }
  },

  methods: {
    rutEsValido() {
      if (this.$refs.inputRut !== undefined) {
        if (this.$refs.inputRut.valid) {
          return true
        }
      }

      return false
    },
    formatFecha(valor) {
      const dato = valor.split('-')

      return typeof dato === typeof [] ? `${dato[2]}-${dato[1]}-${dato[0]}` : 'Sin información'
    },
    async paginar(currentPage) {
      this.page = currentPage
      this.listaPolizas()
    },
    async listaPolizas() {
      this.productos = []
      this.loading = true
      this.perPage = 0 + 1
      const rutFormat = cleanMaskRut(this.rut)

      await polizastService
        .paginacion(this.itemsPerPage, this.page, rutFormat)
        .then(resp => {
          const respuesta = resp.data.statusCode
          if (respuesta == 404 || respuesta == 400) {
            this.isFormSubmittedSnackbarVisible2 = true
            this.textSnackbar = resp.data.message
            this.colorSnackbar = 'error'
            this.respuestaRut = false
            this.stipeActive = false
            this.loading = false
          } else {
            this.pageCount = Math.ceil(resp.data.pagination.pages)
            this.activorut = true
            const informacion = resp.data.data.informacionCliente
            localStorage.setItem('informacionCliente', JSON.stringify(informacion))
            const informacionProducto = resp.data.data.productos
            this.respuestaRut = true
            this.stipeActive = false
            this.informasionRut = informacion
            this.informacionPoliza = resp.data.data

            for (let i = 0; i < informacionProducto.length; i++) {
              const fecha = this.formatFecha(informacionProducto[i].fecha_contratacion)
              const data = {
                codigo: informacionProducto[i].codigo,
                estado: informacionProducto[i].estado,
                fecha_contratacion: fecha,
                producto: informacionProducto[i].producto,
                propuesta: informacionProducto[i].propuesta,
                vigencia: true,
                asegurado_codigo: informacionProducto[i].asegurado_codigo,
                certificado: informacionProducto[i].certificado,
                documento: informacionProducto[i].documento,
                poliza: informacionProducto[i].poliza,
                tieneSolicitudAnulacion: informacionProducto[i].tieneSolicitudAnulacion,
                tipoCodigoAcepta: informacionProducto[i].tipoCodigoAcepta,
                tipoDocumentoCodigo: informacionProducto[i].tipoDocumentoCodigo,
                variablesFirma: informacionProducto[i].variablesFirma,
                vigenciaDesde: informacionProducto[i].vigenciaDesde,
                vigenciaHasta: informacionProducto[i].vigenciaHasta,
              }

              this.correoCliente64 = informacionProducto[i].variablesFirma.correoCliente64
              this.idDocumento64 = informacionProducto[i].variablesFirma.idDocumento64
              this.name64 = informacionProducto[i].variablesFirma.name64
              this.propuesta64 = informacionProducto[i].variablesFirma.propuesta64
              this.rut64 = informacionProducto[i].variablesFirma.rut64
              this.urlPdf64 = informacionProducto[i].variablesFirma.urlPdf64
              this.tipoCodigoAcepta64 = informacionProducto[i].variablesFirma.tipoCodigoAcepta64
              this.documentoPre64 = informacionProducto[i].variablesFirma.documentoPre64
              this.tipoDocumentoCodigo64 = informacionProducto[i].variablesFirma.tipoDocumentoCodigo64
              this.endoso64 = informacionProducto[i].variablesFirma.endoso64

              this.productos.push(data)
              console.log(this.productos)
            }
            this.rut = this.rut
            this.nombres = `${informacion.nombres} ${informacion.primerApellido} ${informacion.segundoApellido}`
            this.primerApellido = informacion.primerApellido
            this.segundoApellido = informacion.segundoApellido
            this.fechaNacimiento = this.formatFecha(informacion.fechaNacimiento)
            this.correo = informacion.correo
            this.telefono = `(${informacion.telefono.substr(0, 3)}) - ${informacion.telefono.substr(
              3,
              3,
            )}-${informacion.telefono.substr(6, 4)}`
            this.loading = false
          }
        })
        .catch(error => {
          console.log(error)
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = 'Rut no existe'
          this.colorSnackbar = 'error'
          this.respuestaRut = false
          this.stipeActive = false
          this.loading = false
        })
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
      this.paginar(1)
    },

    // METODO PARA BUSQUEDA DEL RUT
    async bucarRut(itemRut) {
      this.listaPolizas()
      const rutFormat = cleanMaskRut(itemRut)
      this.rut = rutFormat
      this.rutUser = itemRut
    },
    verCliente() {
      this.$router.push({
        name: 'verCliente',
        params: {
          rut: cleanMaskRut(this.rut),
          informacion: this.informasionRut,
        },
      })
      this.$root.$emit('Cancelcliente', this.informasionRut)
    },
    verDetalle(item) {
      localStorage.setItem('productoPolizaNombre', item.producto)
      this.$router.push({
        name: 'detalleCliente',
        params: {
          rut: cleanMaskRut(this.rut),
          propuesta: item.propuesta,
        },
      })
      this.$root.$emit('Cancelcliente', this.informacionPoliza)
    },
    cancelar_producto(item) {
      console.log('cacelarr', this.informasionRut)
      this.$router.push({
        name: 'cancelarCliente',
        params: {
          id: item,
          rut: this.rut,
          informacion: this.informasionRut,
        },
      })
      this.$root.$emit('Cancelcliente', this.informasionRut)
    },
    refrechData() {
      this.activorut = false
      this.respuestaRut = false
      this.desactivarbuscador = true
      this.productos = []

      this.nombre = ''
      this.nombres = ''
      this.primerApellido = ''
      this.segundoApellido = ''
      this.fechaNacimiento = ''
      this.correo = ''
      this.telefono = ''
      this.rut = ''
      this.rutUser = ''
      this.page = 1
      this.itemsPerPage = 5
    },
    resetData() {
      this.$router.push({
        name: 'poliza',
      })
      this.refrechData()
    },
    verdocumentos(item) {
      console.log(item)
      const decodedStr = `data:application/pdf;base64,${item.documento}`
      this.documentoPDF = decodedStr
      this.tituloDocumento = item.tipoDocumento
    },
    async firmarDocumento() {
      const data = {
        rut64: this.rut64,
        propuesta64: this.propuesta64,
        urlPdf64: this.urlPdf64,
        name64: this.name64,
        idDocumento64: this.idDocumento64,
        correoCliente64: this.correoCliente64,
        tipoCodigoAcepta64: this.tipoCodigoAcepta64,
        documentoPre64: this.documentoPre64,
        tipoDocumentoCodigo64: this.tipoDocumentoCodigo64,
        endoso64: this.endoso64,
      }

      /*
      await FirmaService.getFirma(data).then(res => {
        console.log(res, 'firma')
        this.urlFirma = res.config.url
        this.firmaActiva = true
      })
      console.log(data)
      */
      this.urlFirma = `${process.env.VUE_APP_URL_API_FIRMA}/${data.rut64}/${data.propuesta64}/${data.urlPdf64}/${data.name64}/${data.idDocumento64}/${data.correoCliente64}/${data.endoso64}/${data.tipoDocumentoCodigo64}/${data.tipoCodigoAcepta64}`
      this.firmaActiva = true
    },
    firmadorValido() {
      this.firma_valido = true
      console.log('>>>> Validacion de firmador de doscumento desde el front >>>>>')
      this.cerrar_modal()
      this.paginar(1)
    },
    firmadorNoValido() {
      this.isFormSubmittedSnackbarVisible2 = true
      this.textSnackbar = 'Se requiere firma de documento manual. Por favor imprimir documento'
      this.colorSnackbar = 'info'
      this.loadingToken = false

      this.firma_valido = false
      this.firma_noValido = true
      this.firmaActiva = false
      this.cerrar_modal()
      this.paginar(1)
    },
    cerrar_modal() {
      this.dialog = false
    },
  },
}
</script>
<style scoped>
.v-btn--fab.v-size--x-small {
  height: 20px !important;
  width: 20px !important;
}
.accion {
  margin-bottom: 1px;
}
a {
  text-decoration: none;
}
.textos {
  padding: 5px;
  font-size: 13px;
}
.header-inf-basica {
  background: linear-gradient(98deg, #67afed, var(--v-primary-base) 94%);
  color: white !important;
  font-weight: 300 !important;
  text-align: center;
  border-radius: 10px;
}
.header-inf-basica-text {
  color: white !important;
  font-weight: 300 !important;
}
.PDFdoc {
  overflow: hidden !important;
}
.bonton-firma-icon {
  display: none !important;
}
.bonton-firma {
  display: block !important;
}
@media (max-width: 600px) {
  .bonton-firma-icon {
    display: block !important;
  }
  .bonton-firma {
    display: none !important;
  }
}
@media (min-width: 800px) {
  .tollbar-title {
    margin-left: -95px;
  }
}
@media (min-width: 1000px) {
  .tollbar-title {
    margin-left: -115px;
  }
}
.text-inf {
  font-size: 14px;
}
</style>
