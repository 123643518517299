import axios from 'axios'
import { headers } from './header'

export const polizastService = {
  getPoliza,
  getPolizaDetalle,
  anularPoliza,
  motivoLista,
  paginacion,
}

const URL_API_ADMIN = `${process.env.VUE_APP_API_HOST_ADMIN}/api/v1`
const URL_API_VENTA = `${process.env.VUE_APP_API_HOST_VENTA}/api/v1`

async function getPoliza(rutUser) {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_VENTA}/emision/consultaPoliza?rut=${rutUser}`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}
async function getPolizaDetalle(rutUser, propuesta, data) {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_VENTA}/emision/detallePoliza?rut=${rutUser}&propuesta=${propuesta}`,
    headers: headers(),
    withCredentials: true,
    data,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function anularPoliza(data) {
  const requestOptions = {
    method: 'POST',
    url: `${URL_API_VENTA}/poliza/solicitudAnulacion`,
    headers: headers(),
    withCredentials: true,
    data,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function motivoLista() {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_ADMIN}/motivosOperaciones?tipoOperacionCodigo=3`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function paginacion(currentPage, catidadRegistros, rut) {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_VENTA}/emision/consultaPoliza?rut=${rut}&paginado=true&page=${catidadRegistros}&perPage=${currentPage}`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}
